import {Routes,RouterModule,PreloadAllModules} from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../environments/auth-guard';
import { CtviewComponent } from './ctview/ctview.component';




const APP_ROUTES: Routes = [
    
    {path:'',redirectTo:'/home',pathMatch:'full'},
    {path: 'home', component:HomeComponent},
    {path:'classes',loadChildren:'./classes/classes.module#ClassesModule', canActivate: [AuthGuard]},  
    {path:'teachers',loadChildren:'./teachers/teachers.module#TeachersModule', canActivate: [AuthGuard]},
    {path:'attendance',loadChildren:'./attendance/attendance.module#AttendanceModule', canActivate: [AuthGuard]},
    {path:'gallery',loadChildren:'./gallery/gallery.module#GalleryModule',canActivate: [AuthGuard]},
    {path:'dashboard',loadChildren:'./dashboard/dashboard.module#DashboardModule',canActivate: [AuthGuard]}, 
    {path:'events',loadChildren:'./events/events.module#EventsModule',canActivate: [AuthGuard]}, 
    {path:'custom-sms',loadChildren:'./custom-sms/custom-sms.module#CustomSmsModule',canActivate: [AuthGuard]}, 
    {path:'school',loadChildren:'./school/school.module#SchoolModule',canActivate: [AuthGuard]},
    {path:'carpools',loadChildren:'./carpools/carpools.module#CarpoolsModule',canActivate: [AuthGuard]},
    {path:'fee',loadChildren:'./fee/fee.module#FeeModule',canActivate: [AuthGuard]},
    {path:'report',loadChildren:'./report/report.module#ReportModule',canActivate: [AuthGuard]},
    {path:'child',loadChildren:'./child/child.module#ChildModule',canActivate: [AuthGuard]}, 
    {path:'livestream',loadChildren:'./livestream/livestream.module#LivestreamModule',canActivate: [AuthGuard]},
    // {path:'online-classes',loadChildren:'./online-classes/online-classes.module#OnlineClassesModule',canActivate: [AuthGuard]},
    {path:'allstudents',loadChildren:'./allstudents/allstudents.module#AllstudentsModule',canActivate: [AuthGuard]},
    {path:'libraries',loadChildren:'./libraries/libraries.module#LibrariesModule',canActivate: [AuthGuard]},
   
    
    {path:'routine',loadChildren:'./routines/routine.module#RoutineModule',canActivate: [AuthGuard]},
    {path:'homework',loadChildren:'./homework/homework.module#HomeworkModule',canActivate: [AuthGuard]},

    {path:'holidays',loadChildren:'./holidays/holidays.module#HolidaysModule',canActivate: [AuthGuard]},
    {path:'classwork',loadChildren:'./classwork/classwork.module#ClassworkModule',canActivate: [AuthGuard]},
    
    {path:'marks-assign',loadChildren:'./marks-assign/marks-assign.module#MarksAssignModule',canActivate: [AuthGuard]},
    {path:'excel-reports',loadChildren:'./excel-reports/excel-reports.module#ExcelReportsModule',canActivate: [AuthGuard]},
    {path:'user-rights',loadChildren:'./user-rights/user-rights.module#UserRightsModule',canActivate: [AuthGuard]},
    {path:'request',loadChildren:'./request/request.module#RequestModule',canActivate: [AuthGuard]},

    {path:'uploadform',loadChildren:'./uploadform/uploadform.module#UploadformModule',canActivate: [AuthGuard]},
    {path:'alumni',loadChildren:'./alumni/alumni.module#AlumniModule',canActivate: [AuthGuard]},
    {path:'magazine',loadChildren:'./magazine/magazine.module#MagazineModule',canActivate: [AuthGuard]},
    {path:'Expense Management',loadChildren:'./expense/expense.module#ExpenseModule',canActivate: [AuthGuard]},
    {path:'topper',loadChildren:'./toppers/toppers.module#ToppersModule',canActivate: [AuthGuard]},
    {path:'visitors',loadChildren:'./visitors/visitors.module#VisitorsModule',canActivate: [AuthGuard]},
    {path:'gatecam',loadChildren:'./gatecam/gatecam.module#GatecamModule',canActivate: [AuthGuard]},
    {path:'nonTeaching',loadChildren:'./non-teaching/non-teaching.module#NonTeachingModule',canActivate: [AuthGuard]},
    {path:'Marksview1Component',  loadChildren:"./Marksview1Component/Marksview1Component.module#Marksview1ComponentModule",canActivate:[AuthGuard]},
    {path:'Marksview2Component',  loadChildren:"./Marksview2Component/Marksview2Component.module#Marksview2ComponentModule",canActivate:[AuthGuard]},
    {path:'Marksview3Component', loadChildren:"./Marksview3Component/Marksview3Component.module#Marksview3ComponentModule", canActivate:[AuthGuard]},
    {path:'other-details', loadChildren:"./other-details/other-details.module#OtherDetailsModule", canActivate:[AuthGuard]},
    {path:'tabular', loadChildren:"./tabular/tabular.module#TabularModule", canActivate:[AuthGuard]},
    {path:'extra-details', loadChildren:"./extra-details/extra-details.module#ExtraDetailsModule", canActivate:[AuthGuard]},
    {path:'fee-define', loadChildren:"./fee-define/fee-define.module#FeeDefineModule", canActivate:[AuthGuard]},
    {path:'rank',loadChildren:"./rank/rank.module#RankModule", canActivate:[AuthGuard]},
    {path:'results',loadChildren:"./results/results.module#ResultsModule", canActivate:[AuthGuard]},
    
    {path:'designer',loadChildren:"./designer/designer.module#DesignerModule",canActivate:[AuthGuard]},
    {path:'card', loadChildren:'./card/card.module#CardModule', canActivate:[AuthGuard]},
    {path:'online-classes',loadChildren:'./online-classes/online-classes.module#OnlineClassesModule',canActivate: [AuthGuard]},
    {path: 'design-template',loadChildren:'./design-template/design-template.module#DesignTemplateModule',canActivate: [AuthGuard]},

    {path: 'design-certificate',loadChildren:'./design-certificate/design-certificate.module#DesignerModule',canActivate: [AuthGuard]},
    {path: 'report-cards',loadChildren:'./report-cards/report-cards.module#ReportCardModule',canActivate: [AuthGuard]},
    {path: 'ctview', component: CtviewComponent,canActivate: [AuthGuard]},
];



export const routing = RouterModule.forRoot(APP_ROUTES,{useHash: true,preloadingStrategy: PreloadAllModules})




{


}    
