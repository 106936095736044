import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = function () { };
  window.console.error = function () { };
  window.console.warn = function () { };
  window.console.info = function () { };
  window.console.clear();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(()=>{
  if('serviceWorker' in navigator){
    navigator.serviceWorker.register('ngsw-worker.js');
  }
})
  .catch(err => console.log(err));
