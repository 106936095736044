import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { HostListener, ElementRef } from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpDataServices } from '../../environments/httpData.service';
import { LoginService } from '../../environments/login.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('scrollAnimation', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide', style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ])
  ]
})
export class HomeComponent implements OnInit {
  public lottieConfig: Object;
  public lottieConfig2: Object;
  public lottieConfig3: Object;
  private anim: any;
  users = [];
  private anims: any;
  public isAuthenticated: boolean = false;
  checked = false;
  indeterminate = false;
  isLogin: boolean = false;
  height;
  state = 'hide'
  userImg = '/assets/images/user.svg'

  ngOnInit() {

    if (localStorage["loginData"]) {
      var x = JSON.parse(localStorage.getItem("item1"))
      if (x.TP == 'P') {
        this.router.navigate(['/dashboard', '1']);
      }
      else if (x.TP == 'T') {
        this.loginService.changeAbc('Routine');
        this.router.navigate(['/routine', '0']);
      }
    }
    else {
      this.loginService.logout();
      this.isAuthenticated = false;
    }

  }


  mobileNo: string;
  strRes: string = "";

  form: FormGroup;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public el: ElementRef,
    private httpDataService: HttpDataServices,
    private loginService: LoginService,
    public snackBar: MatSnackBar,
    public _zone: NgZone

  ) {
    this.height = (window.screen.height).toString() + "px";

    this.lottieConfig = {
      path: '/assets/ani.json',
      autoplay: true,
      loop: true
    };
    this.lottieConfig2 = {
      path: '/assets/landpage/data.json',
      autoplay: true,
      loop: true
    };
    this.lottieConfig3 = {
      path: '/assets/anim4.json',
      autoplay: true,
      loop: true
    };
    this.form = fb.group({
      MobileNo: ['', Validators.compose([Validators.required])],
      WebPwd: ['', Validators.compose([Validators.minLength(6), Validators.required])],
      stay: [false],
    });

  }

  setMyStyles() {
    let styles = {
      'min-height': window.innerHeight + 'px',
      'height': window.innerHeight + 'px',
      'overflow': 'auto',
      'max-height': window.innerHeight + 'px',
      'width': '100%',

    };

    return styles;

  }
  loginUser(form: any) {
    this.httpDataService.maingetData('MainDb', 'login', { MobileNo: this.form.value.MobileNo, WebPwd: this.form.value.WebPwd })
      .subscribe(res => {
// console.log(res)

        if (res != null && res != undefined && res != '') {
          if (res[0].Stat == "Y") {
            this.users = res;
            this.users.forEach(ele => {
              if (ele.TP == "T") {
                ele.userType = "Teacher"
              }
              else if (ele.TP == "P") {
                ele.userType = "Principal"
              }
              else if (ele.TP == "S") {
                ele.userType = "Student";
              }

            })

            this.loginService.signIn(form)
            this.isLogin = true;
            
          }
          else if (res[0].Stat == "N") {
            this.openMsg("Invalid ID ", "Error!");
          }
        }

      },
        error => {

          this.openMsg("Invalid ID ", "Error!");
        })


  }

  gotoUser(user) {

    this.loginService.changeItem(user);

    if (this.form.value.stay == true) {
      localStorage.removeItem('item2')
      localStorage.setItem('item2', this.form.value.stay);

    }


    localStorage.removeItem('item1')
    localStorage.setItem('item1', JSON.stringify(user));

    if (user.TP == "P") {
      this.router.navigate(['/dashboard', '1']);

    }
    else if (user.TP == "T") {
      this.loginService.changeAbc('Routine');
      this.router.navigate(['/routine', '0']);

    }
  }
  openDialog(): void {
    var modal = document.getElementById('myModal');
    modal.style.display = "block";
    var span = document.getElementsByClassName("close");
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }

    }

  }

  close() {
    var modal = document.getElementById('myModal');
    modal.style.display = "none";
  }

  handleAnimation(anim: any) {
    this.anim = anim;

  }

  stop() {
    this.anim.stop();

  }

  play() {
    this.anim.play();

  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset

    if (scrollPosition >= 2) {
      this.state = 'show'
      this.anim.play();
      this.anims.play;
    } else {
      this.state = 'hide'
      this.anim.stop();
      this.anims.stop();
    }

  }

  autoLogin() {


    if (localStorage["loginData"]) {

      (<FormGroup>this.form)
        .patchValue(JSON.parse(localStorage.getItem("loginData")), { onlySelf: true });
      this.openDialog()

    }

  }

  openMsg(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,

    });
  }

}
