import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule, MatDatepickerModule, MatDialogModule,
    MatIconModule,
    MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatProgressBarModule, MatSelectModule,
    MatSidenavModule, MatSlideToggleModule, MatSnackBarModule, MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatChipsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatTableModule,
    MatSortModule,
    MatBadgeModule,
    MatTreeModule,
    MatGridListModule
} from '@angular/material';
import {MatExpansionModule} from '@angular/material/expansion';



@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatChipsModule,
        MatFormFieldModule,
        MatStepperModule,
        MatExpansionModule,
        MatTableModule,
        MatSortModule,
        MatBadgeModule,
        MatTreeModule,
        MatGridListModule
        
    ],
    exports: [
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatChipsModule,
        MatFormFieldModule,
        MatStepperModule,
        MatExpansionModule,
        MatTableModule,
        MatSortModule,
        MatBadgeModule,
        MatTreeModule,
        MatGridListModule
       
    ],
    declarations: [],

})
export class MaterialModule { }