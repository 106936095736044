import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/map';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { LoginService } from './login.service';

import * as firebase from 'firebase/app';
import { FileUpload } from 'src/app/classes/student/student.component';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap,map } from 'rxjs/operators';


interface CheckNumberStatusResponse {
  numberExists: boolean;
  chatId: string;
}

interface PostDataResponse {
  // Define the structure of the response for the post data API
  // For example:
  success: boolean;
  message: string;
}


@Injectable()
export class HttpDataServices {

  url;
  imgURL;
  currentImageURL: FileUpload;
  isMobile: Boolean = false;

  private maindb="https://reevweb.azurewebsites.net";
  private mainpostUrl = "https://reevweb.azurewebsites.net";

  private maingetUrl = "https://reevweb.azurewebsites.net/api/requestpost/gethttp?";
  private ImageUrl = "https://www.googleapis.com/upload/drive/v3/files?uploadType=media"
  private rptHttp = 'https://reevweb.azurewebsites.net/ReportViewer.aspx?'


  private rptPostUrl = 'https://reevweb.azurewebsites.net/api/requestpost/GenerateReportAsPDF?'
  private postUrl;
  private getUrl;
  private apiUrl = 'https://api.textlocal.in/send/?';



  private absenteesUrl = 'https://script.google.com/macros/s/AKfycbyJDNrjU2SmkHjzLXhOUJlRiFdWWrgDPnrabLuCJzH-mhohRzU7/exec'

  private dbName;
  private jsonUser;
  private template;
  private schoolName;
  private userName;
  private hash;
  private sender;

  rptUrl = "https://reevweb.azurewebsites.net/ReportViewer.aspx?";

  notUrl = "https://fcm.googleapis.com/fcm/send";
  //  https://api.textlocal.in/send/?username=xyz&hash=8326337ae8bc08d9255f4c3c07d655b1ff07c9c8&sender=xyz


  //whatsapp
  private checkNumberStatusUrl = 'https://chat.reevtech.in/api/checkNumberStatus';
  private sendWhatsappUrl = 'https://chat.reevtech.in/api/sendText'; // Replace with your actual URL

  //whatsapp
  constructor(private http: Http, public Http: HttpClient, private loginService: LoginService) {


    if (JSON.parse(localStorage.getItem('item1')) != null || JSON.parse(localStorage.getItem('item1')) != undefined) {

    }


    this.loginService.currentItems.subscribe(res => {
      if (res != null || res != undefined) {
        this.url = res;
        this.postUrl = res['WebUrl']
        this.getUrl = res['WebUrl']

        this.dbName = res['SchoolCode']
        this.jsonUser = { MobileNo: res['MobileNo'], WebPwd: res['WebPwd'], ID: res['ID'], TP: res['TP'], SchoolCode: res['SchoolCode'] };
        this.template = res['Template'];
        this.schoolName= res['SchoolName'];
      }
    })


  }



  maingetData(dbName: any, pn: any, jsonUser?: any, jsonData?: any) {



    return this.http.get(this.maingetUrl + "dbName=" + dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(jsonUser) + "&JSONData=" + JSON.stringify(jsonData))
      .map((response: Response) => response.json());

  }

  postMainData(dbName: any, pn: any, jsonData?: any) {

    // var headers = new Headers({'Authorization':'password','content-type':'application/json'});
    // let options = new RequestOptions({ headers:headers});


    return this.http.post(this.postUrl + "/api" + "/requestpost" + "/posthttp?" + "dbName=" + dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser), JSON.stringify(jsonData))

      .map((response: Response) => response.json());

  }


  imagePost(jsonData: any) {
    var headers = new Headers({ 'Authorization': 'Bearer ["969929616234-si53jib3fggb3guhe9abv9d59t9v1trt.apps.googleusercontent.com"]', 'Content-Type': 'image/jpeg' });
    let options = new RequestOptions({ headers: headers });



    return this.http.post(this.ImageUrl, jsonData, options)
      .map((response: Response) => response.json());
  }

  // for testing Headers
  getHttpClientdate(pn: any, jsonData?: any) {

    let headers: HttpHeaders = new HttpHeaders;

    headers = headers.append('Content-Type', 'application/json; charset=utf-8');

    return this.Http.get(this.getUrl + "/api" + "/requestpost" + "/gethttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData=" + JSON.stringify(jsonData), {
      headers: headers
    });
  }

  getJSONData(pn: any, jsonData?: any) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
     // 'Accept-Encoding': 'gzip' // Specify that the client accepts gzip-encoded responses
    });
    // console.log(this.getUrl+"/api"+"/requestpost"+"/gethttp?"+ "dbName=" + this.dbName +"&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData=" + JSON.stringify(jsonData))
    return this.http.get(this.getUrl + "/api" + "/requestpost" + "/gethttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData=" + JSON.stringify(jsonData))
      .map((res: Response) => res.json());

  }

  postJSONData(pn: any, jsonData?: any) {
    console.log(JSON.stringify(jsonData));
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      //'Accept-Encoding': 'gzip' // Specify that the client accepts gzip-encoded responses
    });


   return this.http.post(this.postUrl + "/api" + "/requestpost" + "/posthttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser), JSON.stringify(jsonData))
    .map((response: Response) => response.json());


  }

  getData(pn: any, jsonData?: any) {
  
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json' // Set Content-Type header to application/json
    // });
if (jsonData ==undefined || jsonData ==='undefined' ) {
  jsonData='{}';
}
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
     // 'Accept-Encoding': 'gzip' // Specify that the client accepts gzip-encoded responses
    });

     console.log(this.getUrl+"/api"+"/requestpost"+"/gethttp?"+ "dbName=" + this.dbName +"&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData=" + JSON.stringify(jsonData))
    return this.Http.get(this.getUrl + "/api" + "/requestpost" + "/gethttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData=" + JSON.stringify(jsonData),{headers})
      .map((response: Response) => response);

  }


  getReports(rptName: any, pn: any, jsonData: any) {
    // https://reevweb.azurewebsites.net/ReportViewer.aspx?RptName=DashboardAllTeachers&DBName=1002&PN=DashboardAllTeachers&JSONUser="+JSON.stringify(x)+"&JSONData={}
    // console.log(this.rptUrl+"RptName="+rptName+"&DBName="+this.dbName+"&PN="+pn+"&JSONUser="+JSON.stringify(this.jsonUser)+"&JSONData="+JSON.stringify(jsonData))
    return this.rptUrl + "RptName=" + rptName + "&DBName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData=" + JSON.stringify(jsonData);
  }

  



  GetData(pn: any, jsonUser: any, jsonData?: any) {

    if (jsonData ==undefined || jsonData ==='undefined' ) {
      jsonData='{}';
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    //  'Accept-Encoding': 'gzip' // Specify that the client accepts gzip-encoded responses
    });
   
  // console.log('PN ' +  pn +  ' Others' + JSON.stringify(jsonData));


    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json' // Set Content-Type header to application/json
    // });

    //   var headers = new Headers({'Authorization':'password','content-type':'application/json'});
    // let options = new RequestOptions({ headers:headers});
    // console.log(this.dbName);
    // console.log(JSON.parse(localStorage.getItem('item1')));


    // return this.http.get(this.getUrl+"/api"+"/requestpost"+"/gethttp?"+ "dbName=" + this.dbName +"&PN=" + pn + "&JSONUser=" + JSON.stringify(jsonUser) + "&JSONData=" + JSON.stringify(jsonData))
    //   .map((response: Response) => response.json());

    // console.log(this.getUrl+"/api"+"/requestpost"+"/gethttp?"+ "dbName=" + this.dbName +"&PN=" + pn + "&JSONUser=" + JSON.stringify(jsonUser) + "&JSONData=" + JSON.stringify(jsonData));

    return this.Http.get(this.getUrl + "/api" + "/requestpost" + "/gethttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(jsonUser) + "&JSONData=" + JSON.stringify(jsonData),{headers})
      .map((response: Response) => response);
  }

  
  postData(pn: any, jsonData?:any):Observable<any> {
    console.log(JSON.stringify(this.jsonUser)); 
    console.log(JSON.stringify(jsonData));
     
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
     // 'Accept-Encoding': 'gzip' // Specify that the client accepts gzip-encoded responses
    });
    // console.log(this.postUrl+"/api"+"/requestpost"+"/posthttp?"+ "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser),JSON.stringify(jsonData))
    return this.Http.post(this.postUrl + "/api" + "/requestpost" + "/posthttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser), JSON.stringify(jsonData),{ headers })
    .map((response: Response) => response);
    

  }

  // postData(pn: any, jsonUser: any, jsonData?: any): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });

  //   console.log(JSON.stringify(jsonData));
  //   const url = `${this.postUrl}/api/requestpost/posthttp?dbName=${this.dbName}&PN=${pn}&JSONUser=${JSON.stringify(this.jsonUser)}`;
    
  //   return this.Http.post(url, JSON.stringify(jsonData), { headers })
  //     .pipe(
  //       map((response: any) => response)
  //     );
  // }


  getURL(pn: any) {
    return (this.postUrl + "/api" + "/requestpost" + "/posthttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser));
  }



  postChangeData(pn: any, jsonData?: any, db?: any) {
    // for Headers key
    //   var headers = new Headers({'Authorization':'password','content-type':'application/json'});
    //   let options = new RequestOptions({ headers:headers});


    // return this.http.post(this.postUrl+"/api"+"/requestpost"+"/posthttp?"+ "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser),JSON.stringify(jsonData), options )

    // .map((response: Response) => response.json());


    // console.log(this.postUrl+"/api"+"/requestpost"+"/posthttp?"+ "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser),JSON.stringify(jsonData))
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
     // 'Accept-Encoding': 'gzip' // Specify that the client accepts gzip-encoded responses
    });

    return this.Http.post(this.maindb + "/api" + "/requestpost" + "/posthttp?" + "dbName=" + db + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser), JSON.stringify(jsonData),{ headers })
    .map((response: Response) => response);


  }




  registrationData(pn: any, jsonData?: any, db?: any):Observable<any> {
    
  const headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  const url = `${this.maindb}/api/requestpost/posthttp?dbName=${db}&PN=${pn}&JSONUser=${JSON.stringify(this.jsonUser)}`;

  return this.Http.post(url, JSON.stringify(jsonData), { headers })
    .pipe(
      map((response: any) => response)
    );

  }

  postDataTwo(pn: any, jsonData?: any) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.Http.post(this.postUrl + "/api" + "/requestpost" + "/posthttpTwo?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser), JSON.stringify(jsonData),{ headers })
      .map((response: Response) => response);


  }

  getAbsenteesSMSManullay() {

    var url = "https://reevweb.azurewebsites.net/api/requestpost/gethttp?dbName=" + this.dbName + "&PN=SMSAbsentLateList&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData={\"\"}";
    return this.Http.get(url)
      .map((response: Response) => response);
  }
  getAbsenteesSMS() {
    return this.Http.get(this.absenteesUrl + "?schoolCode=" + this.dbName)
      .map((response: Response) => response);
  }

  postRptData(pn: any, RPTName: any, FileName: any, jsonData: any) {

    return this.Http.post(this.rptPostUrl + "DBName=" + this.dbName + "&PN=" + pn + "&RPTName=" + RPTName + "&FileName=" + FileName + "&FolderName=" + this.dbName + "&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData=" + JSON.stringify(jsonData), "")
      .map((response: Response) => response);
  }


  postMainDb(pn: any, jsonData?: any) {

  }

  //Final Post

  PostData(pn: any, jsonUser: any, jsonData?: any): Observable<any> {
 
    console.log(JSON.stringify(jsonUser));
    console.log(JSON.stringify(jsonData));
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

   
    const url = `${this.postUrl}/api/requestpost/posthttp?dbName=${this.dbName}&PN=${pn}&JSONUser=${JSON.stringify(this.jsonUser)}`;
    
    return this.Http.post(url, JSON.stringify(jsonData), { headers })
      .pipe(
        map((response: any) => response)
      );
  }



  // PostData(pn: any, jsonUser: any, jsonData?: any) {

 
  //   //   var headers = new Headers({'Authorization':'password','content-type':'application/json'});
  //   // let options = new RequestOptions({ headers:headers});
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json'
  // });


  // //   return this.http.post(this.postUrl + "/api" + "/requestpost" + "/posthttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(jsonUser), JSON.stringify(jsonData),{headers})

  // //     .map((response: Response) => response.json());

  // return this.Http.post(this.postUrl + "/api" + "/requestpost" + "/posthttp?" + "dbName=" + this.dbName + "&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser), JSON.stringify(jsonData),{ headers })
  // .map((response: Response) => response.json());


  // }

  getReport(RptName: any, DBName: any, PN: any, JSONUser: any, JSONData: any) {
    
    return this.http.get(this.rptHttp + "&RPTName=" + RptName + "&DBName=" +
      DBName + "&PN=" + PN + "&JSONUser=" + JSONUser +
      "&JSONData=" + JSONData)

  }




  notiApi(id: any, page: any, title: any, msg: any) {
    if (id != null && id != undefined && id != '') {

      var x = {
        "to": "/topics/" + id,

        "notification": {
          "body": msg,
          "title": title,
          "sound": "default"
        },
        "priority": "high",
        "data": {
          "click_action": "FLUTTER_NOTIFICATION_CLICK",
          "id": "1", "status": "done", "page": page
        }
      }

      var headers = new Headers({
        'Authorization': "key=AIzaSyD6x6ABlWpTketUEIai716Lfqh6gIkR4II",
        'Content-Type': "application/json",
      });

      let options = new RequestOptions({ headers: headers });

      return this.http.post(this.notUrl, x, options)

    }
  }

  smsApi(mobileNo: any, msg: any) {

    var x;
    this.loginService.currentItems.subscribe(res => {
      x = res
    })

    return this.http.get(this.apiUrl + "username=" + x["userName"] + "&hash=" + x["hashCode"] + "&sender=" + x["sender"] + "&numbers=" + mobileNo + "&message=" + msg + (mobileNo.includes("unicode=1") ? ('%0a' + this.template) : (' %n' + this.template)))
      .map((response: Response) => response.json())

  }

  sendSmsApi(mobileNo: any, msg: any, x: any) {



    //  console.log(this.apiUrl+"username="+x["userName"]+"&hash="+x["hashCode"]+"&sender="+x["sender"]+"&numbers=" + mobileNo+"&message="+msg+' %n'+this.template);
    return this.http.get(this.apiUrl + "username=" + x["userName"] + "&hash=" + x["hashCode"] + "&sender=" + x["sender"] + "&numbers=" + mobileNo + "&message=" + msg + ' %n' + this.template)
      .map((response: Response) => response.json())

  }




  createArticle(pn: any, jsonUser?: any, jsonData?: any) {
    return this.Http.post(this.mainpostUrl + "&PN=" + pn +
      "&JSONUser=" + JSON.stringify(jsonUser), JSON.stringify(jsonData), {
      observe: 'body',
      responseType: 'json'
    }
    );
  }

  saveData(pn: any, jsonUser?: any, jsonData?: any) {
    const params = new HttpParams()
      .set('PN', pn)
      .set('JSONUser', '{}')
      .set('JSONData', JSON.stringify(jsonData));

    return this.Http.post(this.mainpostUrl, {
      params: params
    })
  }

  getDriveToken(){
    return this.http.get('https://salesreevtech.firebaseio.com/driveApi.json').map((response: Response) => response.json())
  }
  /*
    Storage
    * UPLOAD
  */
    fetchToken() {
      return this.Http.get("https://salesreevtech.firebaseio.com/driveApi.json");
    }
  
    uploadFile(file: File, token: any) {
      var _folderID = "1xagFIHd2Ag-x4zWywCSZCsZUso5pMZYT"
      console.log(token.access_token);
      
      var _header = new HttpHeaders({
        'Authorization': "Bearer " + (token.access_token)
      })
      var form = new FormData();
      var metadata = {
        'name': (file.name), // Filename at Google Drive
        'mimeType': (file.type), // mimeType at Google Drive
        'parents': [_folderID], // Folder ID at Google Drive
      };
      form.append('metadata', new Blob([JSON.stringify(metadata)], {
        type: 'application/json'
      }));
      form.append('file', file);
      return this.Http.post("https://www.googleapis.com/upload/drive/v3/files", form, {
        headers : _header,
        reportProgress: true,
      observe: 'events'
      });
  
    }
  

  makeCall(from?: any, to?: any, message?: any) {

    let headers = new Headers({ 'Authorization': 'Basic QUNlZGI2MGQ0YjNlYzA1YjEzZjNiYzkwMDQ0ZTAzYTQxYzowY2U0OTc1ZTM4MGQyNTgzMmQ0YWY3ZTI2MDgxODA4Yw==' });

    let body = new FormData();
    // body.append('To', '+917903827227');
    // body.append('From', '+18552733860');
    body.append('To', '+91' + to);
    body.append('From', from);
    // body.append('Parameters', '{"message":"This is a test message from reev technologies"}');
    body.append('Parameters', '{"message":"' + message + '"}');


    let options = new RequestOptions({ headers: headers });

    return this.http.post("https://studio.twilio.com/v1/Flows/FWecec311b630793d2f39551791a58ed24/Executions", body, options).map((response: Response) => response);

  }

  sendWhatsApp(from?: any, to?: any, message?: any) {

    let headers = new Headers({ 'Authorization': 'Basic QUM2NDhiZDJkMDJlZjMzMGMxYWNiYzU1NmQ0M2ZkOWJkNzplZTMwYThiYzFlMjQ3NTcyNTM2ZjkwM2NkODZmZjUyMw==' });

    let body = new FormData();
    body.append('To', 'whatsapp:+91' + to);
    body.append('From', 'whatsapp:' + from);
    body.append('Body', message);


    let options = new RequestOptions({ headers: headers });

    return this.http.post("https://api.twilio.com/2010-04-01/Accounts/AC648bd2d02ef330c1acbc556d43fd9bd7/Messages.json", body, options).map((response: Response) => response);

  }


  Save(pn: any, jsonUser?: any, jsonData?: any) {


  }

  ImageDelete(Image, folderName) {
    if (Image != null && Image != undefined && Image != '') {
      let Images = [];
      if (typeof (Image) == "string") {
        Images.push(Image)
      }
      else {
        Images = Image;
      }
      var storageRef = firebase.storage();

      if (Images.length > 1) {
        Images.forEach(res => {
          var res = res.split(JSON.parse(localStorage.getItem('item1')).SchoolCode + "%2F" + folderName + "%2F");
          var img = res[1].split("?");
          var desertRef = storageRef.ref(JSON.parse(localStorage.getItem('item1')).SchoolCode + '/' + folderName + '/' + img[0]);


          desertRef.delete().then(() => {



          })


        })
      }

      else if (Images.length == 1) {

        var res = Images[0].split(JSON.parse(localStorage.getItem('item1')).SchoolCode + "%2F" + folderName + "%2F");
        var img = res[1].split("?");
        var desertRef = storageRef.ref().child(JSON.parse(localStorage.getItem('item1')).SchoolCode + '/' + folderName + '/' + img[0])

        desertRef.delete().then(() => {
        }).catch(err => {
          console.log(err);
        })

      }
    }
  }

  baseFolderDelete(folderNm: any) {
    firebase.storage().ref().child(JSON.parse(localStorage.getItem('item1')).SchoolCode + '/' + folderNm).delete().catch(err => {
      console.log(err)
    });
  }

  smsApiTwo(mobileNo: any, msg: any) {

    var x;
    this.loginService.currentItems.subscribe(res => {
      x = res
    })
    return this.http.get(this.apiUrl + "username=" + x["userName"] + "&hash=" + x["hashCode"] + "&sender=" + x["sender"] + "&numbers=" + mobileNo + "&message=" + msg + this.template)
      .map((response: Response) => response.json())

  }

//whatsapp




postWhatsappApi(mobileno: string, whatsappmsg: string): Observable<any> {
  const sess = 'default_' + this.dbName; // Replace with your session logic
  const data = { 
    chatId: mobileno,
    text: whatsappmsg + "*" + this.schoolName.toUpperCase()  + "*" ,
    session: sess
};
    //
    return this.http.post(this.sendWhatsappUrl, data)

    .map((response: Response) => response.json());

    //
}

// checkAndPostData(phone: string, text: string): Observable<any> {
//   const session = 'default_' + this.dbName; // Replace with your session logic

//   return this.checkNumberStatus(phone, session).pipe(
//     switchMap((response:any) => {
//       console.log('Response from checkNumberStatus:', response);
//       if (response && response.numberExists) {
//         console.log('Number exists, proceeding to send WhatsApp message');
//         return this.postWhatsappApi(phone, session, text);
//       } else {
//         console.log('Number does not exist');
//         return throwError('Number does not exist');
//       }
//     }),
//     catchError(error => {
//       console.error('Error in check and post data', error);
//       return throwError(error);
//     })
//   );
// }

checkNumberStatus(phone: string) {
  // const headers = new HttpHeaders({
  //   'Content-Type': 'application/json' // Set Content-Type header to application/json
  // });
  const session = 'default_' + this.dbName; // Replace with your session logic

  const headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  const url = `${this.checkNumberStatusUrl}?session=${session}&phone=${phone}`;
  

  // console.log(this.getUrl+"/api"+"/requestpost"+"/gethttp?"+ "dbName=" + this.dbName +"&PN=" + pn + "&JSONUser=" + JSON.stringify(this.jsonUser) + "&JSONData=" + JSON.stringify(jsonData))
  return this.Http.get(url,{headers})
    .map((response: Response) => response);

}

//whatsapp

  ImageUpload() { }

  // mediumImageCompress(e){
  //   const width = 400;
  //     const height = 200;
  //     const fileName = e.target.files[0].name;
  //     const reader = new FileReader();

  //     reader.readAsDataURL(e.target.files[0]);

  //     reader.onload = event => {
  //         const img = new Image();
  //        this.imgURL= event.target['result']
  //        console.log(this.imgURL)
  //         img.src = event.target['result'];
  //         img.onload = () => {
  //                 const elem = document.createElement('canvas');

  //                 elem.width = width;
  //                 elem.height = height;
  //                 const ctx = elem.getContext('2d');


  //                 ctx.drawImage(img, 0, 0, width, height);

  //                 ctx.canvas.toBlob((blob) => {
  //                     var file = new File([blob], fileName, {
  //                         type: 'image/jpeg',
  //                         lastModified: Date.now()

  //                     });
  //                     this.currentImageURL = new FileUpload(file);
  //                 }, 'image/jpeg', 1);


  //             },
  //             reader.onerror = error => console.log(error);
  //     };
  // }


  

}