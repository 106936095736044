import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  isAuthenticated: boolean = false;
  isPrincipal: boolean = false;
  isTeacher: boolean = false;
  isStudent: boolean = false;
  isProgress: boolean = false;
  constructor(private router: Router) { }

  private refreshProfile = new BehaviorSubject<string>('')
  refreshCurrentProfile = this.refreshProfile.asObservable();


  refreshSetProfile(itm: any) {
    this.refreshProfile.next(itm);

  }



  private profileItm = new BehaviorSubject<string>('')
  currentProfile = this.profileItm.asObservable();


  setProfile(itm: any) {
    this.profileItm.next(itm);

  }



  private fees = new BehaviorSubject<string>('')
  currentfees = this.fees.asObservable();


  changeFees(items: any) {
    this.fees.next(items);
  }

  private items = new BehaviorSubject<string>('')
  currentItems = this.items.asObservable();




  changeItem(item: any) {

    this.items.next(item)


    if (item.Stat == "Y") {
      this.isAuthenticated = true;
    }

    if (item.TP == "P") {
      this.isPrincipal = true;
      this.router.navigate(['/dashboard', '1']);
    }

    if (item.TP == "T") {
      this.isTeacher = true;
      this.router.navigate(['/routine', '0']);
    }

    if (item.TP == "S") {
      this.isStudent = true;
    }


  }

  logout() {
    // localStorage.removeItem("item1");
    // localStorage.removeItem("item2");
    
    localStorage.removeItem("item3");
    this.isAuthenticated = false;
    this.isPrincipal = false;
    this.isTeacher = false;
    this.isStudent = false;
    
    this.router.navigate(['']);
  }


  private items1 = new BehaviorSubject<string>('')
  currentUser = this.items1.asObservable();

  changeUser(item: any) {
    // console.log(item);
    this.items1.next(item)
  }


  public abc = new BehaviorSubject<string>('')
  currentAbc = this.abc.asObservable();

  changeAbc(itm: any) {
    return this.abc.next(itm);
  }
  private Tc = new BehaviorSubject<string>('')
  currentT = this.Tc.asObservable();

  changeTc(itm: any) {
    this.Tc.next(itm)
  }


  private Routine = new BehaviorSubject<string>('')
  currentRoutine = this.Routine.asObservable();

  changeRoutine(item: any) {
    this.Routine.next(item);

  }


  private category = new BehaviorSubject<string>('')
  currentCategory = this.category.asObservable();

  currentCategories(category: any) {
    this.category.next(category);
  }

  signIn(form: any) {

    if (form.value.stay) {
      localStorage.removeItem('loginData');
      localStorage.setItem("loginData", JSON.stringify(form.value));

    }

  }
}
