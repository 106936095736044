import { LoginService } from './login.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private loginService: LoginService, private router: Router) { }

    canActivate(): boolean {

        

        let authenticated = false;

        if (this.loginService.isAuthenticated) {
            authenticated = true;
        }
        else {
            this.router.navigate(['/home']);
        }
      
        return authenticated;
    }
}