import { Component, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../environments/login.service';
import { SwUpdate } from '@angular/service-worker';
import { MatDrawer } from '@angular/material';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpDataServices } from 'src/environments/httpData.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  @HostBinding('class') componentCssClass;

  hideBars: boolean = true;
  changeNo;
  isHide: boolean = false;
  notiCount = 0;
  excelItm;
  pwdForm: FormGroup
  isPwd: boolean = true
  isdashboard: boolean = false;
  isExcel: boolean = false;
  isSms: boolean = false;
  isMobile: boolean = false;
  isWeb: boolean = false;
  items;
  schoolToolbar = false;
  cate;
  SchName: string;
  UserName;
  Password;
  tets = [];
  isLoading: boolean = false;
  dateNow = new Date();
  ct: boolean = false;
  color: "#";
  ky = [];
  x = [];
  isExpand: boolean = false;
  isAttendance: boolean = false;
  isSearch: boolean = false;
  isClassTeacher: boolean = false;
  cats = [];
  tc = "";
  defaultCover: string = "assets/images/princiCover.jpg";
  defaultImage1 = "assets/images/Logo.png";

  defaultImage: string = "assets/images/MRH_logo.png";
  @ViewChild('drawer') public myNav: MatDrawer;
  @ViewChild(MatDrawer) public sidenav: MatDrawer;
  isAttnSubMenu: boolean = false;

  // when isAttnSubMenu is False then in Teacher Login default Attendance Menu will dispaly.

  // for Users Role
  menuItem = [];
  reportItem = [];


  constructor(elementRef: ElementRef, public fb: FormBuilder,
    private chdf: ChangeDetectorRef,
    private router: Router,
    public loginService: LoginService,
    private swUpdate: SwUpdate,
    private pwdFb: FormBuilder,
    private httpDataService: HttpDataServices,
    private overlayContainer: OverlayContainer
  ) {

    loginService.refreshCurrentProfile.subscribe(res => {
      this.getProfileList();
    })


    loginService.currentAbc.subscribe(res => {

      this.tc = res;
      if(this.tc == 'hidebars'){
        this.hideBars = false
      }
      else{
        this.hideBars = true
      }
      
    });

    this.pwdForm = this.pwdFb.group({
      newPwd: ['', Validators.required],
      retypePwd: ['', Validators.required]
    })


    // for update

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("update Available")) {
          window.location.reload();
        }
      })
    }

    this.loginService.currentItems.subscribe(res => {
      if (res != null || res != undefined) {
        this.cate = res;

      }
      else {

      }
    })

  }

  isMenuShow(itm) {
    var x: boolean = false;
    if (itm.Menu != "Attendance" && itm.Menu != "Send SMS/Notifications" && itm.Menu != "Mobile Content Panel" &&
      itm.Menu != 'Web Control Panel') {
      x = true;
    }
    return x;
  }

  isProgress() {
    return this.loginService.isProgress
  }

  getToolbar() {
    var x;
    this.isSearch = false;
    if (this.tc == "Teachers" || this.tc == "Students" || this.tc == "Drivers") {

      if (window.innerWidth < 640) {
        this.isSearch = true;
        x = {
          "height": "120px"

        }
      }
      else {
        x = {
          "min-height": "60px",
          "max-height": "60px"
        }
      }

    }
    else {

      x = {
        "min-height": "60px",
        "max-height": "60px"
      }
    }
    return x;
  }

  ngOnInit() {

    this.componentCssClass = localStorage.getItem("docTheme")
    this.myNav.autoFocus = false;
    if (JSON.parse(localStorage.getItem('item2')) == true) {

      this.loginService.changeItem(JSON.parse(localStorage.getItem('item1')));

    }

    this.loginService.currentItems.subscribe(res => {
      localStorage.removeItem("schoolDetails")
      localStorage.setItem('schoolDetails',JSON.stringify(res))
      this.items = [];
      this.items = res;
      console.log(this.items);
      this.changeNo = res['MobileNo'];
      this.SchName = res['SchoolName']
      if (res['ctStat'] == 'Y' && res['TP'] == 'T') {
        this.isClassTeacher = true;
        this.ct = true;
      }

      if (res['TP'] == 'T' && res['ctStat'] == 'N') {
        this.ct = true;
        this.isClassTeacher = false;
      }

      // USer Right Data
      this.menuItem = [];
      this.reportItem = [];
      this.httpDataService.getData('RoleUsers', { ID: res['ID'] })
        .subscribe(rx => {

          if (rx != null && rx != undefined && rx.toString() != '') {
            rx[0].Menus = JSON.parse(rx[0].Menus)

            if (rx[0].Menus != null && rx[0].Menus != undefined && rx[0].Menus != '') {
              this.menuItem = rx[0].Menus
              rx[0].Menus.forEach(ele => {
                if (ele.Menu == "Attendance") {
                  this.isAttnSubMenu = true;
                }

              })
            }
            if (rx[0].Reports != null && rx[0].Reports != undefined && rx[0].Reports != '') {
              rx[0].Reports = JSON.parse(rx[0].Reports)
              this.reportItem = rx[0].Reports

              if (this.reportItem.length > 0) {
                this.isHide = true;
              }
              else {
                this.isHide = false
              }

            }
          }


        })

    });

    this.getProfileList();

  }

  setTheme(setItm) {
    this.overlayContainer.getContainerElement().classList.add(setItm);
    this.componentCssClass = setItm;

    localStorage.setItem("docTheme", this.componentCssClass);
  }

  changePwd() {

    var modal = document.getElementById('myPwdModal');

    modal.style.display = "block";

  }
  updatePwd() {

    var x = { MNo: this.changeNo, WPwd: this.pwdForm.value.retypePwd }
    this.httpDataService.postData('PwdSave', x)
      .subscribe(res => {

        if (res.toString() == "Y") {

          this.httpDataService.postMainData('MainDb', 'PwdSave', x).subscribe(res => {

            this.pwdForm.reset()
            this.cancelPwd()
            this.logOut();
          })

        }
      })

  }
  cancelPwd() {
    var modal = document.getElementById('myPwdModal');
    modal.style.display = "none";
  }

  logOut() {

    localStorage.removeItem("item1");
    localStorage.removeItem("item2");
    localStorage.removeItem("loginData")
    this.loginService.logout();
    this.items = [];
    this.ngOnInit();

  }
  isLoggedIn() {
    return this.loginService.isAuthenticated;

  }

  ngAfterViewChecked() {
    this.dateNow = new Date();
    this.chdf.detectChanges();
  }


  isTeacher() {
    return this.loginService.isTeacher;
  }


  isAdmin() {

    return this.loginService.isPrincipal;
  }

  isStudent() {

    return this.loginService.isStudent

  }

  alterMsg(drawer) {
    this.loginService.currentItems.subscribe(res => {
      this.UserName = res['userName'];
      this.Password = res['SMSPwd'];

    })
    var modal = document.getElementById('SmsStat');
    modal.style.display = "block";
    drawer.close();

  }


  alertClose() {
    var msg = document.getElementById('SmsStat');
    msg.style.display = "none";
    window.open("http://sms.reevtech.in")

  }

  getProfileList() {
    this.httpDataService.getData('ProfileList', {})
      .subscribe(res => {


        if (res != null && res != undefined) {
          this.notiCount = Object.keys(res).length;
          this.loginService.setProfile(res)
        }

      })
  }

  gotoStdRequest() {
    this.router.navigate(['/request'])
  }


  openNav(drawer) {
    drawer.open();
  }

  gotoReportCards(drawer){
    this.router.navigate(['/report-cards', 'Marks'])
    drawer.close();
  }

  gotoExcelMasters(drawer, itm) {
    this.excelItm = itm;
    this.router.navigate(['/excel-reports', this.excelItm])
    drawer.close();
  }
  nonTeachingStaff(drawer) {
    drawer.close();
    this.router.navigate(['/nonTeaching'])
  }
  gotoHolidays(itm, drawer) {
    drawer.close();
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("holidayMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/holidays'])
  }
  gotoMagazine(itm, drawer) {
    drawer.close();
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("webControlMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/magazine'])
  }

  gotoAddmission(itm, drawer) {
    drawer.close();
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("formMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/uploadform'])
  }
  gotoRegForm(itm, drawer) {
    drawer.close();
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("formMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/uploadform', 'id'])
  }
  gotoResults(itm, drawer) {
    drawer.close();
    this.loginService.changeAbc("Results")
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("formMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/results'])
    
  }
  gotoAlumini(drawer) {
    drawer.close();
    this.router.navigate(['/alumni'])
  }
  gotoTopper(itm, drawer) {
    drawer.close();
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("topperMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/topper'])
  }
  gotoHeadline(itm, drawer) {
    drawer.close();
    this.loginService.changeAbc("Headline")
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("formMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/results','1'])
    
  }
  gotoUserRights(drawer) {
    drawer.close();
    this.router.navigate(['/user-rights'])

  }
  gotoReport(drawer) {
    drawer.close();
    this.loginService.changeAbc("Exams");
    setTimeout(() => {
      this.router.navigate(['/report']);
    }, 250);

  }
  gotoIdCard(drawer) {
    drawer.close();
    this.loginService.changeAbc("ID Card");
  
      this.router.navigate(['/card']);
    

  }

  gotoclasswork(drawer) {
    drawer.close()
    this.loginService.changeAbc("Classwork")
    this.router.navigate(['/classwork']);
  }
  gotoSms(itm, drawer) {
    drawer.close()
    this.loginService.changeAbc("SMS")
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("smsMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/custom-sms'])
  }
  teacherWiseSMS(itm, drawer) {
    drawer.close()
    this.loginService.changeAbc("SMS")
    if (itm != null || itm != undefined || itm != '') {
      localStorage.removeItem("smsMenu")
      localStorage.setItem("smsMenu", JSON.stringify(itm))
    }
    this.router.navigate(['/custom-sms', '1', '2', '3', '4'])
  }
  gotoLibrary(drawer) {
    drawer.close()
    this.loginService.changeAbc("Library")
    this.router.navigate(['/libraries'])
  }
  gotoExpenses(drawer) {
    drawer.close()
    this.router.navigate(['/Expense Management'])
  }
  gotoGateCamera(drawer) {
    drawer.close()
    this.router.navigate(['/gatecam'])
  }
  gotoLive(drawer) {
    drawer.close()
    this.router.navigate(['/gatecam','1'])
  }
  gotoVisitors(drawer) {
    drawer.close()
    this.router.navigate(['/visitors'])
  }
  gotoEvents(itm, drawer) {
    drawer.close()
    this.loginService.changeAbc("Events")
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("mobileContentEvent", JSON.stringify(itm))
    }
    this.router.navigate(['/events'])
  }
  gotoGallery(itm, drawer) {
    drawer.close()
    this.loginService.changeAbc("Gallery")
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("maobileContentGallery", JSON.stringify(itm))
    }
    this.router.navigate(['/gallery'])
  }
  gotoLiveStream(itm, drawer) {
    drawer.close()
    this.loginService.changeAbc("Live Stream")
    if (itm != null || itm != undefined || itm != '') {
      localStorage.setItem("maobileContentVideo", JSON.stringify(itm))
    }
    this.router.navigate(['/livestream'])
  }
  gotoWebSiteDesigner(drawer){
    drawer.close()
    this.loginService.changeAbc("Designer")
    this.router.navigate(['/designer'])
  }
  gotoSetting(drawer) {
    drawer.close()
    this.loginService.changeAbc("Settings")
    this.router.navigate(['/school'])
  }
  gotoFee(drawer) {
    drawer.close();
    this.loginService.changeAbc("Fees");
    this.router.navigate(['/fee']);
  }
  goToTeacher(drawer) {
    drawer.close();
    this.loginService.changeAbc("Teachers");
    this.router.navigate(['/teachers', '0', 'All']);

  }

  goToAllStudents(drawer) {
    drawer.close();
    setTimeout(() => {
      this.router.navigate(['/allstudents', 'all']);
    }, 250);

  }
  gotoTC(drawer) {
    drawer.close();
    setTimeout(() => {
      this.router.navigate(['/allstudents', 'TC']);
    }, 250);
  }

  gotoTransport(drawer) {
    drawer.close();
    this.router.navigate(['/carpools', '0']);
  }
  goToClasses(drawer) {
    drawer.close();
    this.loginService.changeAbc("Classrooms");
    this.router.navigate(['/classes']);
  }

  sAttendance(itm, drawer) {
    drawer.close();
    this.loginService.changeAbc("Attendance");
    localStorage.setItem("menu", JSON.stringify(itm))
    this.router.navigate(['/attendance', 'S']);
  }

  tAttendance(drawer) {
    drawer.close();
    this.loginService.changeAbc("Attendance");
    this.router.navigate(['/attendance', 0, 0, 'T']);
  }
  nTeachingAttn(drawer) {
    drawer.close();
    this.loginService.changeAbc("Non Teaching");
    this.router.navigate(['/attendance', 1, 2,3,4,'N']);
  }


  teacherProfile(drawer, i) {
    drawer.close();
    localStorage.removeItem("menu")
    this.loginService.changeAbc("Profile");
    this.router.navigate(['/teachers', i]);
  }

  teacherRoutine(drawer, i) {
    drawer.close();
    this.loginService.changeAbc("Routine");
    this.router.navigate(['/routine', i]);
  }

  viewAttendance(drawer) {
    drawer.close();
    this.loginService.changeAbc("Attendance");
    this.router.navigate(['/attendance', 0, 0, 'T']);
  }
  gotoGroupwise(itm, drawer) {
    drawer.close();
    localStorage.setItem("menu", JSON.stringify(itm))
    this.router.navigate(['/custom-sms', '0', '0'])
  }
  markAttendance(drawer) {
    drawer.close();
    this.loginService.changeAbc("Attendance");
    this.router.navigate(['/attendance', 'S']);
  }
  subAttendance(drawer) {
    drawer.close();
    this.loginService.changeAbc("Attendance");
    this.router.navigate(['/attendance', '1','2','3','S']);
  }

  teacherGallery(drawer) {
    drawer.close();
    this.loginService.changeAbc("Gallery");
    localStorage.removeItem("maobileContentGallery")
    this.router.navigate(['/gallery']);
  }
  teacherEvent(drawer) {
    drawer.close();
    localStorage.removeItem("mobileContentEvent")
    this.loginService.changeAbc("Event");

    this.router.navigate(['/events']);
  }
  markAssign(drawer) {
    drawer.close();
    this.loginService.changeAbc("Marks");
    let x = JSON.parse(localStorage.getItem('item1'));

    setTimeout(() => {
      this.router.navigate(['/marks-assign', '0', x.ctStat + '-' + x.CT]);
    }, 250);

  }
  ctview(drawer) {
    drawer.close();
    this.loginService.changeAbc("Master View");
    this.router.navigate(['/ctview']);
  }

  teacherLiveStream(drawer) {
    drawer.close();
    this.loginService.changeAbc("Livestream");
    localStorage.removeItem("maobileContentVideo");
    this.router.navigate(['/livestream']);
  }
  onlineTutorial(drawer) {
    drawer.close();
    
    var schoolDetails = JSON.parse(localStorage.getItem("schoolDetails"))
    var url = "https://small-talk-e5bcb.web.app/admin?schoolCode=" + schoolDetails.SchoolCode.toString()
    localStorage.removeItem("online_classes")
    localStorage.setItem('online_classes', url)
    this.router.navigate(['/online-classes']);
   
  }

  onlineExams(drawer){

    drawer.close();
    
    var schoolDetails = JSON.parse(localStorage.getItem("schoolDetails"))
    var url = "https://reev-exam.web.app/admin?schoolCode=" + schoolDetails.SchoolCode.toString()
    localStorage.removeItem("online_classes")
    localStorage.setItem('online_classes', url)
    this.router.navigate(['/online-classes']);

  }

  gotoReportMenu(itm, drawer) {
    drawer.close();
    if (itm.Menu == "Dashboard") {
      this.router.navigate(['/dashboard', '']);
    }
    else if (itm.Menu == "Masters") {
      this.gotoExcelMasters(drawer, itm.Menu)
    }
    else if (itm.Menu == "Class Routine") {
      this.gotoExcelMasters(drawer, itm.Menu)
    }
    else if (itm.Menu == "Attendance") {
      this.gotoExcelMasters(drawer, itm.Menu)
    }
    else if (itm.Menu == "Marks") {
      this.gotoExcelMasters(drawer, itm.Menu)
    }
    else if (itm.Menu == "Fees") {
      this.gotoExcelMasters(drawer, itm.Menu)
    }
    else if (itm.Menu == "Library") {
      this.gotoExcelMasters(drawer, itm.Menu)
    }
    else if (itm.Menu == "Transfer Certificate") {
      this.gotoTC(drawer)
    }

  }

  gotoMenu(itm, drawer) {
    drawer.close();

    if (itm.Menu == "All Student") {

      localStorage.setItem("menu", JSON.stringify(itm))
      this.goToAllStudents(drawer)
    }
    else if (itm.Menu == "Teachers") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.goToTeacher(drawer)
    }
    else if (itm.Menu == "Classrooms") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.goToClasses(drawer)
    }
    else if (itm.Menu == "Fees Analysis") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.gotoFee(drawer)
    }
    else if (itm.Menu == "Report Card") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.gotoReport(drawer)
    }
    else if (itm.Menu == "View CW/HW") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.gotoclasswork(drawer)
    }
    else if (itm.Menu == "Library Management") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.gotoLibrary(drawer)
    }
    else if (itm.Menu == "Settings") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.gotoSetting(drawer)
    }
    else if (itm.Menu == "Carpools") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.gotoTransport(drawer)
    }
    
    else if (itm.Menu == "Visitors") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.gotoVisitors(drawer)
    }
    else if (itm.Menu == "GateCamera") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.gotoGateCamera(drawer)
    }
    else if (itm.Menu == "Non-Teaching Staff") {
      localStorage.setItem("menu", JSON.stringify(itm))
      this.nonTeachingStaff(drawer)
    }

  }
}
