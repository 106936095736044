import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpDataServices } from 'src/environments/httpData.service';
import { LoginService } from 'src/environments/login.service';
declare var Stimulsoft: any;
@Component({
  selector: 'app-ctview',
  templateUrl: './ctview.component.html',
  styleUrls: ['./ctview.component.css']
})
export class CtviewComponent implements OnInit {
  class: string = '';
  sec: string = '';

  form: FormGroup;
  Terms = [];
  act: boolean = false;
  termDef = [];
  options: string[] = ['Qualified', 'Needs Improvement'];
  Remarks = '';

  items: any;
  abc: any;
  yr: any;
  cl: any;
  se: any;
  stID: any;


  constructor(
    private loginService: LoginService, 
    private snackBar: MatSnackBar, 
    private httpDataService: HttpDataServices,
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public router:Router
    
    
    ) { 
      this.form = this.fb.group({

        Term: ['', Validators.required]
      })
  
    }

  ngOnInit() {
    this.loadTerms();
    // this.initialiseReport();
  }



  loadTerms(){
    // this.activatedRoute.params.subscribe(res => {
    //   this.abc = res.st;
    //   let x = this.abc.split('-');
    //   this.cl = x[1];
    //   if (x[2] != '') {
    //     this.se = x[2]
    //   }
    //   else {
    //     this.se = '-';
    //   }


    // })

//

this.loginService.isProgress = true;
    this.loginService.currentItems.subscribe(res => {
      var ct = res['CT'];
      ct = ct.replace('-', '%reev');
      this.class = ct.split('%reev')[0].toString();
      this.sec = ct.split('%reev')[1].toString();
    })
  

    this.httpDataService.getData('SettingList', {})
      .subscribe(res => {

        this.yr = res[0].AcademicYr
        console.log(this.yr);


      })


    this.httpDataService.getData('SettingList', {})
      .subscribe(res => {


        let x = JSON.parse(res[0].ExamTerms);

        x.forEach(ele => {
          this.act = ele.Active

          if (ele.Active == true) {
            this.Terms.push(ele);

          }

          this.termDef[ele.name] = ele.Active
        
        })

        console.log(this.Terms);
        console.log(this.termDef);

      })
    this.stID = JSON.parse(localStorage.getItem('item3'))
    console.log('stID ' + this.stID);
    this.loginService.isProgress = false;
  }


  
viewReport(){
  this.loginService.isProgress = true;
  this.httpDataService.getData("excel_reportcardmarkslist",{Class: this.class,Sec: this.sec,Term: this.form.get('Term').value}).subscribe(res => {

    var dataSet = new Stimulsoft.System.Data.DataSet("dat");
    dataSet.readJson(JSON.stringify(res));
    
    var report = new Stimulsoft.Report.StiReport();
    report.loadFile('assets/reports/excel_reportcardmarkslist.mrt');
    report.dictionary.databases.clear();
    report.regData(dataSet.dataSetName, "", dataSet);
    var schoolDetails = localStorage.getItem("schoolDetails");
    report.regData("school", "", schoolDetails);

    
    report.renderAsync(async () => {
      var viewer = new Stimulsoft.Viewer.StiViewer(null, "StiViewer", false)
      viewer.report = report

      viewer.renderHtml('viewer');
      // this.closeFiltersModal();
      this.loginService.isProgress = false;
    });

  })
}


  initialiseReport() {
    this.loginService.isProgress = true;
    this.loginService.currentItems.subscribe(res => {
      var ct = res['CT'];
      ct = ct.replace('-', '%reev');
      this.class = ct.split('%reev')[0].toString();
      this.sec = ct.split('%reev')[1].toString();
      this.httpDataService.getData('SettingList', {})
      .subscribe(res2 => {
        var exam_terms = JSON.parse(res2[0].ExamTerms);
        var exam_term = exam_terms.filter(x => x.Active == true)[0].name;
        var y = { Class: this.class, Sec: this.sec, Term: exam_term, Subject: "", TeacherID: "" }
console.log(y);
        this.httpDataService.getData("ReportCardMarksList", y).subscribe((resDetails:any) => {
console.log(resDetails);  
      
          var dataSet = new Stimulsoft.System.Data.DataSet("marks");
          dataSet.readJson(JSON.stringify(resDetails));
          console.log(resDetails);
    
          var report = new Stimulsoft.Report.StiReport();
          report.loadFile('assets/reports/ColumnWiseReportWithTotal_Ab.mrt');
          report.dictionary.databases.clear();
          report.regData(dataSet.dataSetName, "", dataSet);
    
          var schoolDetails = localStorage.getItem("schoolDetails")
          report.regData("school", "", schoolDetails);
    
          report.renderAsync(async () => {
            var viewer = new Stimulsoft.Viewer.StiViewer(null, "StiViewer", false)
            viewer.report = report
    
            viewer.renderHtml('viewer');
            this.loginService.isProgress = false;
          })
        })
      })
      



    });
  }


  goBack(){
    this.router.navigate(['/ctview','']);
  }

}

