import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './app.routing';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './material/material.module';
import { LottieAnimationViewModule } from 'lottie-angular2';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import * as firebase from 'firebase/app'
import { HttpClientModule } from '@angular/common/http';
import 'hammerjs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpDataServices } from '../environments/httpData.service';
import { LoginService } from '../environments/login.service';
import { AuthGuard } from '../environments/auth-guard';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { TeacherRoutineComponent } from './teacher-routine/teacher-routine.component';
import { MAT_DATE_LOCALE } from '@angular/material';
import { OnlineClassComponent } from './online-classes/online-class/online-class.component';
import { CtviewComponent } from './ctview/ctview.component';
// import { ExtraDetailsComponent } from './extra-details/extra-details.component';

firebase.initializeApp({   
  
  // For Live
  
  apiKey: "AIzaSyCPEbJ3dE-X2z1ON1Yk5bUd86sdvCZCV1s",
  authDomain: "school-5beb1.firebaseapp.com",
  databaseURL: "https://school-5beb1.firebaseio.com",
  projectId: "school-5beb1",
  storageBucket: "school-5beb1.appspot.com",
  messagingSenderId: "883224188712" 


  // For Testing

  // apiKey: "AIzaSyAJ60Vk65YSTdRa1-3S66WqfdfpkDVTIjg",
  // authDomain: "http-a06ab.firebaseapp.com",
  // databaseURL: "https://http-a06ab.firebaseio.com",
  // projectId: "http-a06ab",
  // storageBucket: "http-a06ab.appspot.com",
  // messagingSenderId: "81737153088",
  // appId: "1:81737153088:web:a40eb348d9f26a67"

  })

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TeacherRoutineComponent,
    CtviewComponent
    
    
  ],
  imports: [
            BrowserModule,
            BrowserAnimationsModule,
            routing,
            [MaterialModule],
            LottieAnimationViewModule.forRoot(),
            ReactiveFormsModule,
            HttpModule,
            HttpClientModule,
            FormsModule,
            [FlexLayoutModule],
            HttpClientModule,
            ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
          
        ],
  providers: [HttpDataServices,LoginService,AuthGuard ,{provide: MAT_DATE_LOCALE, useValue: 'en-GB'},],
  entryComponents: [TeacherRoutineComponent],
  bootstrap: [AppComponent]
})

export class AppModule { }
