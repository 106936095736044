import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpDataServices } from '../../environments/httpData.service';

@Component({
  selector: 'app-teacher-routine',
  templateUrl: './teacher-routine.component.html',
  styleUrls: ['./teacher-routine.component.scss'],

})
export class TeacherRoutineComponent implements OnInit {


  form:FormGroup;
  constructor(public dialogRef: MatDialogRef<TeacherRoutineComponent>,public fb:FormBuilder,private httpDataService: HttpDataServices,
    @Inject(MAT_DIALOG_DATA) public data = []) { 

this.form = this.fb.group({
  Class:['',Validators.required],
  Section:['',Validators.required],
})

    }
  selectedClass = [];
  selectedSection = [];
  selectedValue: string;

  classes = [];
  sections = [];

  ngOnInit() {


    this.httpDataService.getData('allClasses',{})
    .subscribe(res => {

      Object.keys(res).map(k => {
        this.classes.push(res[k].class);
        if(res[k].cards != null){
        JSON.parse(res[k].cards).forEach(element => {
          
          this.sections.push(element.Sec);
          this.sections = Array.from(new Set(this.sections));
        });
      }
      })
      
    },
  error => {})

  }

  getclass() {
    
    this.data = this.form.value.Class.concat('=', this.form.value.Section);

    
    this.closeDialog();
  }
  closeDialog() {

    this.dialogRef.close(this.data);
  }
}
